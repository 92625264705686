import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import { MainLayout, SubmitApplicationForm } from '../components'

export default function SubmitApplicationPage() {
	return (
		<>
			<MainLayout>
				<Helmet title="Deenee: Submit Application" />
				<section className="mb-16">
					<SubmitApplicationForm />
				</section>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
